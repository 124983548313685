.scroller {
  position: relative;
  box-sizing: border-box;
  width: 3000px;
  display: flex;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(90deg, transparent, #fff 50%);
  mask-image: linear-gradient(90deg, transparent, #fff 50%);
}
.scroller div {
  display: inline-flex;
  white-space: nowrap;
  flex-direction: row;
  animation: animate var(--t) linear infinite;
  animation-delay: calc(var(--t) * -1);
}
.scroller div:nth-child(2) {
  animation: animate2 var(--t) linear infinite;
  animation-delay: calc(var(--t) / -2);
}
@keyframes animate {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes animate2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200%);
  }
}
@keyframes animateReverse {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
@keyframes animateReverse2 {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(0);
  }
}
.scroller div span {
  display: inline-flex;
  border-radius: 5px;
  transition: 0.5s;
}

.testimonials {
  position: relative;
  box-sizing: border-box;
  -webkit-mask-image: linear-gradient(90deg, transparent, #fff 10%, #fff 90%, transparent);
  mask-image: linear-gradient(90deg, transparent, #fff 10%, #fff 90%, transparent);
}
.testimonials div.set {
  animation: animate var(--t) linear infinite;
  animation-delay: calc(var(--t) * -1);
}
.testimonials div.set:nth-child(2) {
  animation: animate2 var(--t) linear infinite;
  animation-delay: calc(var(--t) / -2);
}
.testimonials.reverse div.set {
  animation: animateReverse var(--t) linear infinite;
  animation-delay: calc(var(--t) * -1);
}
.testimonials.reverse div.set:nth-child(2) {
  animation: animateReverse2 var(--t) linear infinite;
  animation-delay: calc(var(--t) / -2);
}
.testimonials:hover div.set {
  animation-play-state: paused;
}
.testimonials:hover div.set:nth-child(2) {
  animation-play-state: paused;
}
.testimonials.reverse:hover div.set {
  animation-play-state: paused;
}
.testimonials.reverse:hover div.set:nth-child(2) {
  animation-play-state: paused;
}
